<script lang="ts">
	import Logo from '$lib/components/layout/Logo.svelte';
	import { theme } from '$lib/stores/theme';
	import ThemeController from '$lib/components/layout/ThemeController.svelte';
	import Icon from '@iconify/svelte';
	import { cookies } from '$lib/stores/cookies';

	const socials: {
		href: string;
		title: string;
		icon: string;
	}[] = [
		{
			href: 'https://x.com/did0f/',
			title: 'Twitter',
			icon: 'fa6-brands:x-twitter'
		},
		{
			href: 'https://github.com/didof',
			title: 'GitHub',
			icon: 'skill-icons:github-dark'
		},
		{
			href: 'https://www.linkedin.com/in/didof/',
			title: 'LinkedIn',
			icon: 'skill-icons:linkedin'
		},
		{
			href: 'https://dev.to/didof',
			title: 'Dev.to',
			icon: 'fa6-brands:dev'
		}
	] as const;
</script>

<footer
	class="footer footer-center gap-y-6 bg-gradient-to-t from-base-200 to-transparent text-base-content rounded p-10"
>
	<div>
		<Logo />
	</div>
	<nav class="grid grid-cols-2 sm:grid-cols-4 gap-6">
		<a href="/eula/" class="link link-hover">EULA</a>
		<a href="/privacy-policy/" class="link link-hover">Privacy Policy</a>
		<a href="/cookie-policy/" class="link link-hover">Cookie Policy</a>
		<a href="/refund-policy/" class="link link-hover">Refund Policy</a>
	</nav>
	<div class="flex items-center flex-wrap justify-around gap-4">
		<div class="flex items-center justify-center flex-wrap gap-4">
			<button type="button" class="btn btn-circle btn-ghost" on:click={theme.toggle}>
				<span class="sr-only">Toggle Appearance</span>
				<ThemeController />
			</button>
			<button type="button" class="btn btn-circle btn-ghost" on:click={cookies.addCheckboxes}>
				<span class="sr-only">Manage Cookies</span>
				<Icon icon="ic:round-cookie" width="20" height="20" />
			</button>
		</div>
		<nav class="flex items-center justify-center flex-wrap gap-4">
			{#each socials as { href, title, icon }}
				<a {href} target="_blank" class="btn btn-circle btn-ghost">
					<span class="sr-only">{title}</span>
					<Icon {icon} width="20" height="20" />
				</a>
			{/each}
		</nav>
	</div>

	<aside class="w-full flex items-center gap-8">
		<span class="flex-1 text-right">
			Copyright © {new Date().getFullYear()}
		</span>
		<a href="https://l13o.com/" target="_blank" rel="noopener noreferrer" class="flex-1 text-left inline-block link link-hover">
			L13o s.r.l.
		</a>
	</aside>
</footer>
