<script lang="ts">
	import '../app.css';
	import DebugScreens from '$lib/components/dev/DebugScreens.svelte';
	import { theme } from '$lib/stores/theme';
	import Header from '$lib/components/layout/Header.svelte';
	import Footer from '$lib/components/layout/Footer.svelte';
	import Toasts from '$lib/components/interaction/Toasts.svelte';
	import { cookies } from '$lib/stores/cookies';

	theme.init();
	setTimeout(() => cookies.init(), 0);
</script>

<svelte:head>
	<link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
	<link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
	<link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
	<link rel="manifest" href="/site.webmanifest" />
</svelte:head>

<Header class="z-50" />
<div class="z-40 pt-16">
	<slot />
</div>
<Toasts />
<Footer />

<DebugScreens />
