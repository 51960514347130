<script lang="ts">
	import { theme } from '$lib/stores/theme';
	import ThemeController from '$lib/components/layout/ThemeController.svelte';
	import { cn } from '$lib/utils/style';
	import Icon from '@iconify/svelte';
	import Logo from './Logo.svelte';
	import { browser } from '$app/environment';
	import { flyAndScale } from '$lib/utils/transitionts';
	import { toasts } from '../interaction/Toasts.svelte';

	let className = '';
	export { className as class };

	let scrollY = browser ? window.scrollY : 0;
	let lastScrollY = scrollY;
	let scrollingUp = false;
</script>

<svelte:window
	on:scroll|passive={() => {
		scrollY = window.scrollY;
		scrollingUp = scrollY <= lastScrollY;
		lastScrollY = scrollY;
	}}
/>

<div
	class={cn(
		'fixed top-0 left-0',
		'transition-transform will-change-transform',
		scrollY <= 64 || scrollingUp ? 'translate-y-0' : '-translate-y-full',
		className
	)}
>
	<header class="w-screen h-16 navbar px-4 backdrop-blur-lg bg-base-100/80">
		<Logo />

		<div class="ml-auto flex items-center">
			<button type="button" class="btn btn-sm btn-circle btn-ghost mr-1" on:click={theme.toggle}>
				<ThemeController />
			</button>

			<div class="grid place-content-center">
				<div class="divider divider-horizontal mx-1 h-8"></div>
			</div>
			<a href="https://dashboard.easypagego.com/" target="_blank" class="btn btn-sm btn-ghost mr-2">
				Log in
			</a>
			<a href="/#pricing" class="hidden sm:inline-flex btn btn-sm btn-primary">
				<Icon icon="solar:rocket-bold-duotone" width="20" height="20" />
				<span> Get started </span>
			</a>
		</div>
	</header>
</div>

{#if $toasts.length == 0 && scrollY > 50}
	<button
		transition:flyAndScale={{
			duration: 200,
			start: 0.5,
			y: 50
		}}
		type="button"
		class="sm:hidden fixed bottom-4 right-2 btn btn-secondary border-0 bg-opacity-60 backdrop-blur-md btn-circle"
		style:z-index={80}
		on:click={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
	>
		<Icon icon="majesticons:chevron-up" width="36" height="36" />
	</button>
{/if}
